import React, { ReactElement } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import Heading from 'components/Heading'
import Loader from 'components/Loader'
import style from './index.module.css'
import cn from 'classnames'

interface InfinitePayload {
  title?: string
  caption?: string
  length: number
  hasMore: boolean
  children: ReactElement | ReactElement[]
  fetchMore: () => void
  largeNegativeMargin?: boolean
  className?: string
}

export function InfiniteCompoundListZone(props: InfinitePayload) {
  const {
    title,
    caption,
    hasMore,
    length,
    children,
    fetchMore,
    largeNegativeMargin,
    className,
  } = props

  return (
    <InfiniteScroll
      dataLength={length}
      next={fetchMore}
      hasMore={hasMore}
      loader={<Loader />}
      className={className}
    >
      <div
        className={cn(
          largeNegativeMargin
            ? style.headerLargeNegativeMargin
            : style.headerSmallNegativeMargin
        )}
      >
        {title && <Heading value={title} caption={caption} />}
      </div>
      <div className={`feed`}>
        {React.Children.map(children, (child: ReactElement) => {
          return React.cloneElement(child, { key: child.key })
        })}
      </div>
    </InfiniteScroll>
  )
}
