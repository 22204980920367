import { useState, useEffect } from 'react'
import { useCurrentUser } from './useCurrentUser'
import { useTopicSubscribers } from './useTopicSubscribers'
interface UserTopicState {
  errorMsg: string
  isSubscribedToTopic: boolean
  isChangingSelection: boolean
  selectedTopicIdsSet: Set<string>
}

export const useFollowTopic = ({ topicId }: { topicId: string }) => {
  const { user } = useCurrentUser()
  const { subscribeToTopic, unsubscribeFromTopic } = useTopicSubscribers()
  const [userTopicsState, setUserTopicsState] = useState<UserTopicState>({
    errorMsg: '',
    isSubscribedToTopic: false,
    isChangingSelection: false,
    selectedTopicIdsSet: new Set(),
  })

  async function setFollowTopicState({ willFollow }: { willFollow: boolean }) {
    const { selectedTopicIdsSet } = userTopicsState
    const currentSelectedIds = new Set(selectedTopicIdsSet)

    if (willFollow) {
      currentSelectedIds.add(topicId)
    } else {
      currentSelectedIds.delete(topicId)
    }
    setUserTopicsState({
      errorMsg: '',
      isChangingSelection: true,
      selectedTopicIdsSet: currentSelectedIds,
      isSubscribedToTopic: !!user && currentSelectedIds.has(topicId),
    })
    const { data, error } = await (willFollow
      ? subscribeToTopic({ topicId })
      : unsubscribeFromTopic({ topicId }))
    if (error) {
      if (willFollow) {
        currentSelectedIds.delete(topicId)
      } else {
        currentSelectedIds.add(topicId)
      }
      setUserTopicsState({
        isChangingSelection: false,
        selectedTopicIdsSet: currentSelectedIds,
        errorMsg: `Unable to unsubscribe from ${topicId}`,
        isSubscribedToTopic: !!user && currentSelectedIds.has(topicId),
      })
    }
    if (data) {
      setUserTopicsState({
        isChangingSelection: false,
        errorMsg: '',
        selectedTopicIdsSet: currentSelectedIds,
        isSubscribedToTopic: !!user && currentSelectedIds.has(topicId),
      })
    }
  }

  useEffect(() => {
    if (!user) {
      return setUserTopicsState({
        ...userTopicsState,
        selectedTopicIdsSet: new Set(),
        isSubscribedToTopic: false,
      })
    }
    const { topicsList } = user
    const selectedTopicIdsSet = new Set(topicsList.map(({ id }) => id))
    setUserTopicsState({
      ...userTopicsState,
      selectedTopicIdsSet,
      isSubscribedToTopic: !!user && selectedTopicIdsSet.has(topicId),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return {
    ...userTopicsState,
    subscribeToTopic: () => setFollowTopicState({ willFollow: true }),
    unsubscribeFromTopic: () => setFollowTopicState({ willFollow: false }),
  }
}
