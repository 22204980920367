import cn from 'classnames'
import style from './index.module.css'
import { ReactNode } from 'react'

interface HeadingPayload {
  value?: ReactNode
  caption?: string
  isMinimal?: boolean
  isInline?: boolean
  link?: string
}

export default function Heading(props: HeadingPayload) {
  const { value, caption, isMinimal = false, isInline = false, link } = props

  const Tag = link ? 'a' : 'h1'

  return (
    <Tag
      href={link && link}
      className={cn(
        style.heading,
        { [style.minimal]: isMinimal },
        { [style.inline]: isInline }
      )}
    >
      <span className={style.title}>{value}</span>
      {caption && <span className={style.caption}>{caption}</span>}
    </Tag>
  )
}
