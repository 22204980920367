import cn from 'classnames'
import PlusSign from 'components/IconSvgs/PlusSign'
import { useFollowTopic } from 'hooks/useFollowTopic'
import { useGlobalModalContext } from 'hooks/useGlobalModalContext'
import { useCurrentUser } from 'hooks/useCurrentUser'
import style from './index.module.css'

export default function Pill({ id, name }: any) {
  const { user } = useCurrentUser()
  const {
    isSubscribedToTopic,
    subscribeToTopic,
    unsubscribeFromTopic,
  } = useFollowTopic({ topicId: id })

  const { toggleModalOpen } = useGlobalModalContext()

  function openModal() {
    return toggleModalOpen()
  }

  const nameParts = name.split(' ').slice(0, 4)
  const displayName =
    name.length > 25 && nameParts.join(' ') !== name
      ? nameParts.join(' ') + '...'
      : name
  if (user) {
    return (
      <div className={style.pillWrapper}>
        <button
          onClick={() =>
            isSubscribedToTopic ? unsubscribeFromTopic() : subscribeToTopic()
          }
          aria-label={
            isSubscribedToTopic
              ? `Unsubscribe from ${name}`
              : `Subscribe to ${name}`
          }
          className={cn({ [style.active]: isSubscribedToTopic }, style.pill)}
        >
          <span>{displayName} </span>
          <div className={style.plusSignContainer}>
            <span
              title={name}
              className={
                !isSubscribedToTopic
                  ? style.plusSign
                  : cn(style.plusSign, style.plusSignRotated)
              }
            >
              <PlusSign active={isSubscribedToTopic || false} />
            </span>
          </div>
        </button>
      </div>
    )
  }
  return (
    <div className={style.pillWrapper}>
      <button
        aria-label={
          isSubscribedToTopic
            ? `Unsubscribe from ${name}`
            : `Subscribe to ${name}`
        }
        onClick={() => openModal()}
        className={cn({ [style.active]: isSubscribedToTopic }, style.pill)}
      >
        <span>{displayName} </span>
        <div className={style.plusSignContainer}>
          <span
            title={name}
            className={
              !isSubscribedToTopic
                ? style.plusSign
                : cn(style.plusSign, style.plusSignRotated)
            }
          >
            <PlusSign active={isSubscribedToTopic || false} />
          </span>
        </div>
      </button>
    </div>
  )
}

Pill.displayName = 'PillClip'
