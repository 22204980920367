import { useMutation } from '@apollo/client'
import { SUBSCRIBE_USER_TO_TOPIC } from 'graphql/mutations/SubscribeUserToTopic'
import { UNSUBSCRIBE_USER_TO_TOPIC } from 'graphql/mutations/UnsubscribeUserFromTopic'
import {
  SubscribeUserToTopic,
  SubscribeUserToTopicVariables,
} from 'types/generated/SubscribeUserToTopic'
import {
  UnsubscribeUserFromTopic,
  UnsubscribeUserFromTopicVariables,
} from 'types/generated/UnsubscribeUserFromTopic'

export const useTopicSubscribers = () => {
  const [subscribeToTopic] = useMutation<
    SubscribeUserToTopic,
    SubscribeUserToTopicVariables
  >(SUBSCRIBE_USER_TO_TOPIC)
  const [unsubscribeFromTopic] = useMutation<
    UnsubscribeUserFromTopic,
    UnsubscribeUserFromTopicVariables
  >(UNSUBSCRIBE_USER_TO_TOPIC)

  return {
    subscribeToTopic: async (input: SubscribeUserToTopicVariables['input']) => {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore this is a mistyped result, errors absolutely exists
        const { data, errors } = await subscribeToTopic({
          variables: { input },
        })
        const error = errors
          ? new Error(errors?.map((e: Error) => e.message).join('\n'))
          : null
        return { data, error: error }
      } catch (e) {
        console.error(e)
        return { data: null, error: e as Error }
      }
    },
    unsubscribeFromTopic: async (
      input: UnsubscribeUserFromTopicVariables['input']
    ) => {
      try {
        const { data } = await unsubscribeFromTopic({ variables: { input } })
        return { data, error: null }
      } catch (e) {
        console.error(e)
        return { data: null, error: e as Error }
      }
    },
  }
}
