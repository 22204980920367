import style from './index.module.css'
import cn from 'classnames'

interface LoaderPayload {
  isFull?: boolean
}

export default function Loader(props: LoaderPayload) {
  const { isFull = false } = props

  return (
    <h1 className={cn(style.loader, { [style.full]: !!isFull })}>Loading</h1>
  )
}

export function SmallLoader() {
  return <h1 className={style.smallLoader}>Loading</h1>
}
