import gql from 'graphql-tag'
import { PrivateUserFragment } from '../fragments/UserFragment'

export const UNSUBSCRIBE_USER_TO_TOPIC = gql`
  mutation UnsubscribeUserFromTopic($input: UnsubscribeUserFromTopicInput!) {
    unsubscribeUserFromTopic(input: $input) {
      user {
        ...PrivateUserFragment
      }
    }
  }
  ${PrivateUserFragment}
`
