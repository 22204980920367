import gql from 'graphql-tag'
import { PrivateUserFragment } from '../fragments/UserFragment'

export const SUBSCRIBE_USER_TO_TOPIC = gql`
  mutation SubscribeUserToTopic($input: SubscribeUserToTopicInput!) {
    subscribeUserToTopic(input: $input) {
      user {
        ...PrivateUserFragment
      }
    }
  }
  ${PrivateUserFragment}
`
