import { buildImgixURL } from '../../../lib/images'
import { StructuredData, SeoTags } from '../../MetaTags'

const siteUrl = 'https://thedipp.com/'
const logoUrl = `https://thedipp.imgix.net/2020/08/TheDipp_Logo_stack-black.png`
const siteName = 'The Dipp'
const image = {
  url: buildImgixURL(logoUrl, { size: [1200, 630] }),
  width: 1200,
  height: 630,
  alt: siteName,
}
const description = 'A community prioritizing women and their passions'
const defaultSEO = {
  url: siteUrl,
  title: siteName,
  description,
  openGraph: {
    type: 'website',
    locale: 'en_US',
    url: siteUrl,
    site_name: siteName,
    image,
  },
  twitter: {
    site: '@thedipp',
    cardType: 'summary_large_image',
  },
}

export const structuredPublisherData = {
  name: siteName,
  url: siteUrl,
  logo: image,
}

export default function HomepageMetaTags() {
  return (
    <>
      <SeoTags {...defaultSEO} />
      <StructuredData
        {...{
          page: {
            url: siteUrl,
            title: siteName,
            description,
            image,
          },
        }}
      />
    </>
  )
}
